<template>
    <div style="width:100%">
        <div class="of-form-row">
            <el-form-item label="Instrument" prop="isin">
                <el-select 
                    v-model="formdata.isin"
                    @change="get_changed_isin_data">
                    <el-option
                        v-for="item in currency_list"
                        :key="item.code"
                        :label="item.code"
                        :value="item.code">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Quantity" prop="q">
                <el-input
                    v-model="formdata.q"
                    @blur="blur_format_field('q')" />
            </el-form-item>

            <el-form-item label="Portfolio Conversion Rate" prop="tcur_prate" v-if="show_conversion_rate">
                <el-input
                    v-model="formdata.tcur_prate"
                    @blur="blur_format_field('tcur_prate')">
                    <template slot="prepend">{{formdata.isin}}{{portfolio_cur}}</template>    
                </el-input>
            </el-form-item>
            <el-form-item v-else></el-form-item>    

            <el-form-item label="Portfolio Trade Amount" prop="tcur_prate_amount" v-if="show_conversion_rate">
                <el-input
                    v-model="formdata.tcur_prate_amount"
                    @blur="blur_format_field('tcur_prate_amount')"
                    @change="change_tcur_prate_amount">
                    <template slot="prepend">{{portfolio_cur}}</template>    
                </el-input>
            </el-form-item>
            <el-form-item v-else></el-form-item> 

            <el-form-item></el-form-item>
            <el-form-item></el-form-item>
        </div>
    </div>
</template>

<script>
import common from './operation_mixin'
import { num_to_money } from '@/utils'

export default {
    mixins: [common],

    data(){
        return {
            asset: 'cash'
        }
    },

    computed: {
        show_conversion_rate(){
            if (!this.formdata.isin)
                return false;
            else if (this.formdata.isin === this.portfolio_cur)
                return false;
            return true;
        }
    },

    methods: {
        get_changed_isin_data(){
            if (!this.formdata.isin) return;
            
            this.isin_data = { isin: this.formdata.isin };

            if (this.formdata.isin && this.formdata.tcur && this.formdata.isin === this.formdata.tcur){
                this.$set(this.formdata, 'tcur_prate', 1);
                return;
            }

            let ident = this.formdata.isin + this.portfolio_cur
            this.get_history_values(ident, this.asset, ['price', 'date', 'name'], this.trade_date).then((response) => {
                this.$set(this.isin_data, 'price', response.price)
                this.$set(this.isin_data, 'date', response.date)
                this.$set(this.isin_data, 'name', response.name)
                if (response.price) 
                    this.$set(this.formdata, 'tcur_prate', response.price);
                else 
                    this.$set(this.formdata, 'tcur_prate', 1)
            })
        },

        load_isin(isin){
            this.$set(this.formdata, 'isin', isin)
            this.get_changed_isin_data()
        },

        change_tcur_prate_amount(){
            let tcur_prate_amount = this.get_number_field('tcur_prate_amount')
            let q = this.get_number_field('q')
            if (q) this.$set(this.formdata, 'tcur_prate', tcur_prate_amount / q);
            else this.$set(this.formdata, 'tcur_prate', 1);
        }
    },

    watch: {
        portfolio_cur(val) {
            this.get_changed_isin_data();
        },
        trade_date(val){
            if (!this.operationid)
                this.get_changed_isin_data();
        },

        "formdata.q": function(val, old_val) {
            if (old_val === val) return;

            let q = this.get_number_field('q')
            let tcur_prate = this.get_number_field('tcur_prate')

            this.$set(this.formdata, 'tcur_prate_amount', q * tcur_prate)
        },

        "formdata.tcur_prate": function(val, old_val) {
            if (old_val === val) return;

            let q = this.get_number_field('q')
            let tcur_prate = this.get_number_field('tcur_prate')
            
            this.$set(this.formdata, 'tcur_prate_amount', q * tcur_prate)
        },
    },

}
</script>
